import React from 'react';
import iconPlayAlt from '../../images/podcast-play.png';
import PodcastWaterMark from '../../images/PodCastWaterMark.svg';
export default function PodCastTopSliderItem({ podCast, className, showWaterMark, isPlay }) {
  const { data } = podCast;

  return (
    <div className={`${className ? className : 'carousel-item carousel-video media ratio_21-9'}`}>
      {showWaterMark && (
        <div>
          <img src={PodcastWaterMark} style={{ position: 'absolute', zIndex: '200', right: 0 }} />
        </div>
      )}
      <div className="btn btn-transparent podcast-slider">
        {isPlay ? (
          <div className="pause-icon" style={{ margin: 'auto', width: 30, height: 40 }}></div>
        ) : (
          <img
            src={iconPlayAlt}
            alt="Icon Play"
            className="icon-play-alt"
            style={{ margin: 'auto', width: 47, height: 40 }}
          />
        )}
      </div>
      <div className="m_overlay-gradient"></div>
      <div
        className="m_overlay-image bgImage"
        style={{
          backgroundImage: `url(${data.thumnailImage.url})`,
        }}
      />
      <div className="media-content">
        <h3 className="media-title ">{data.podCastTitle[0].text}</h3>
      </div>
      {true && (
        <div className="m_run-time m_run-time-outer">
          <span>{data.podcastDuration}</span>
        </div>
      )}
    </div>
  );
}

import React from 'react';

const ImageSlider = ({ images }) => {
  const [scrollLeft, setScrollLeft] = React.useState(0);
  const ref = React.useRef();

  React.useEffect(() => {
    const div = ref.current;
    if (div) {
      div.addEventListener('scroll', (event) => {
        event.preventDefault();
        const div = document.querySelector('.slider-scroll');
        var maxScrollLeft = div.scrollWidth - div.clientWidth;
        const value = (div.scrollLeft / maxScrollLeft) * 70;
        setScrollLeft(value);
      });
    }
  }, [ref]);

  return (
    <div className="image-slider">
      <div
        ref={ref}
        className="d-flex columns-3 slider-scroll"
        style={{
          flexWrap: 'nowrap',
          overflowX: 'scroll',
          scrollbarWidth: 'none',
        }}
      >
        {images?.map((elm, index) => (
          <div
            className="cols m_overlay-image bgImage image-scroll-lists"
            style={{
              backgroundImage: `url(${elm.photo.url})`,
              height: '210px',
              marginLeft: index === 0 ? index : 14,
              flexShrink: 0,
            }}
          ></div>
        ))}
      </div>
      <div className="scroll-track">
        <div className="scroll-thumb" style={{ marginLeft: `${scrollLeft}%` }}></div>
      </div>
    </div>
  );
};

export default ImageSlider;

import React from 'react';
import ImageSlider from './image-slider';

const PeopleDescriptions = ({ dataActionPhoto, dataName, dataMinistryRole, descriptions, images }) => {
  return (
    <div>
      <div className="cols" style={{ flex: 1 }}>
        <div className="info-head">
          <div
            className="people-figure bgImage"
            style={{
              backgroundImage: `url(${dataActionPhoto})`,
            }}
          ></div>
          <div style={{ marginTop: 'auto' }}>
            <span className="people-title">{dataName}</span>
            <div className="descriptions" style={{ marginTop: '5px' }}>
              {dataMinistryRole.map((item) => (
                <p>{item.text}</p>
              ))}
            </div>
          </div>
        </div>
        <div>
          {descriptions.map((item) => {
            return (
              <p className="people-details" style={{ marginTop: 10 }}>
                {item.text}
              </p>
            );
          })}
        </div>
      </div>
      <div>
        <ImageSlider images={images} />
      </div>
    </div>
  );
};

export default PeopleDescriptions;
